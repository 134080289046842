import React from "react";

export default function AcercaDe() {
  return (
    <div>
      <h2>Acerca de</h2>
      <h3>Metodolodía</h3>
      En construcción
      <h3>Cálculos</h3>
      En construcción
      <h3>Quienes Somos</h3>
      En construcción
    </div>
  );
}
