import React from "react";
// import logo from "loginpolo.jpg";

export default function HomePage() {
  return (
    <div>
      <img src={"loginpolo.jpg"} alt="logo" style={{ width: "90%" }}></img>
    </div>
  );
}
